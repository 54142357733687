import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import anaSayfaImg from "../assets/img/anaSayfa.jpg"; // JPG dosyası
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["iç mimarlık", "dış mimarlık", "çevre tasarımı"];
  const period = 2000;

  useEffect(() => {
    const ticker = setInterval(() => {
      tick();
    }, delta);

    return () => clearInterval(ticker);
  }, [text]);

  const tick = () => {
    const i = loopNum % toRotate.length;
    const fullText = toRotate[i];
    const updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Welcome to Ala Group</span>
                  <h1>{`Ala Group`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "iç mimarlık", "dış mimarlık", "çevre tasarımı" ]'><span className="wrap">{text}</span></span></h1>
                  <p>Ala group olarak tasarımın ve estetiğin gücüne inanan bir ekibiz. Her projemizde, sadece binalar değil, yaşam alanları inşa ediyoruz. İnce detaylara verdiğimiz önem ve yaratıcı çözümlerimizle, her bir projemizde özgün dokunuşlar katıyoruz. Müşterilerimizin hayallerini gerçeğe dönüştürmek için çalışıyoruz. Her bir tasarımımızda, estetikle fonksiyonun mükemmel bir denge içinde olmasına özen gösteriyoruz. Ekibimiz, çeşitli yeteneklere ve deneyimlere sahip, tutkulu bir grup uzmanı barındırıyor. Tanıtım filmimizde, projelerimizin arka planında yatan hikayeleri ve tasarım felsefemizi keşfedeceksiniz. Seray Mimarlık olarak, mimarlık dünyasına değer katmak ve sürdürülebilir, estetik açıdan zengin çözümler sunmak için buradayız. Bize katılın, mimari vizyonumuzu paylaşın. Seray Mimarlık ile yaşam alanlarınızı daha özel hale getirin!</p>
                  <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={anaSayfaImg} alt="Ana Sayfa" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
