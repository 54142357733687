import React from 'react';
import '../App.css'; // Adjust path based on your folder structure
import resim161 from '../assets/img/resim161.jpg';
import resim162 from '../assets/img/resim162.jpg';
import resim163 from '../assets/img/resim163.jpg';
import resim165 from '../assets/img/resim165.jpg';

const services = [
  {
    title: 'Mimarlık',
    description: `Projenin gerçekleştirileceği yere alan gezisi yapılması ve topoğrafyanın çıkarılması ile birlikte projenin ilk adımları SERAY MİMARLIK tarafından atılır. Analizlerin yapılmasının ardından kütlesel ilişkiler, strüktür, fonksiyon ve estetik bileşenler projeye işlenir. Alanlarında yetkin farklı disiplinlerden uzmanlardan alınan danışmanlıklarla da, işi şansa bırakmadan en doğru çözüme ulaşılmaya çalışılır.`,
    imageUrl: resim161,
  },
  {
    title: 'Danışmanlık',
    description: `Mimarlığın farklı aktörlerle birlikte pratiğe dökülebilen bir meslek olduğunu biliyoruz. Dolayısıyla her bir projemizde alanında yetkin, farklı disiplinlerden çözüm ortaklarımıza danışarak ilerliyoruz. Peyzaj, aydınlatma, akustik, yangın, deprem, cephe, LEED… gibi birçok uzmanlık alanları danışmanlık aldığımız başlıca konulardır.`,
    imageUrl: resim162,
  },
  {
    title: 'İç Mimarlık',
    description: `İç mimarlık ofisleri olarak kullanıcının istekleri ve hayalindeki mekan tasarım kararlarımızın merkezinde yer almaktadır. Kullanıcının mekânsal ihtiyaçları sonucunda ihtiyaç programını en doğru şekilde belirleriz. Kullanıcının hayallerini tasarımda ve uygulamada tecrübeli ekibimizle beraber gerçekleştiririz.`,
    imageUrl: resim163,
  },
  {
    title: 'Uygulama',
    description: `Konsept projenin belirlenmesinin ardından şantiye süreci için gereken yasal hazırlıklar SERAY MİMARLIK tarafından takip edilir. Malzeme tedariği ve yerleştirilmesi usta ekibimiz tarafında yapılır ve ofis tarafından sürekli kontrol edilir.`,
    imageUrl: resim165
  },
];

const Hizmetler = () => {
  return (
    <div className="container-fluid py-lg-5" id="hizmetler">
      {services.map((service, index) => (
        <div key={index} className="container py-lg-5 py-4 wow fadeInUp hizmetone-wrap" data-wow-delay="0.1s">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-2">
                <h1 className="mb-0">{service.title}</h1>
              </div>
              <p className="mb-4">{service.description}</p>
            </div>
            <div className="col-lg-5" style={{ minHeight: '400px' }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={service.imageUrl}
                  style={{ objectFit: 'cover' }}
                  alt={service.title}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Hizmetler;
