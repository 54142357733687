import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import icmekan from "../assets/img/içmekan.jpg";
import mutfak from "../assets/img/mutfak.jpg";
import oturma from "../assets/img/oturmaodasi.jpg";
import salon from "../assets/img/salondekorasyon.jpg";
import yatak from "../assets/img/yatakodasi.jpg";
import ana from "../assets/img/anasalon.jpg"
import dis1 from "../assets/img/dis-1.jpg"
import dis2 from "../assets/img/dis-2.jpg"
import dis3 from "../assets/img/dis-3.jpg"
import dis4 from "../assets/img/dis-4.jpg"
import aydinlatma1 from "../assets/img/aydinlatma1.jpg"
import aydinlatma2 from "../assets/img/aydinlatma-2.jpg"
import aydinlatma3 from "../assets/img/aydinlatma-3.jpg"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const ic = [
    {
      title: "İç Mekan",
      description: "Design & Development",
      imgUrl: icmekan,
    },
    {
      title: "Mutfak",
      description: "Design & Development",
      imgUrl: mutfak,
    },
    {
      title: "Oturma Odasi",
      description: "Design & Development",
      imgUrl: oturma,
    },
    {
      title: "Salon Dekarasyon",
      description: "Design & Development",
      imgUrl: salon,
    },
    {
      title: "Yatak Odasi",
      description: "Design & Development",
      imgUrl: yatak,
    },
    {
      title: "Ana salon",
      description: "Design & Development",
      imgUrl: ana,
    },
  ];

  const dis = [
    {
      title: "first",
      description: "firstDescription",
      imgUrl: dis1,
    },
    {
      title: "second",
      description: "secondDescription",
      imgUrl: dis2,
    },
    {
      title: "third",
      description: "thirdDescription",
      imgUrl: dis3,
    },
    {
      title: "forth",
      description: "forthDescription",
      imgUrl: dis4,
    },
  ];


  const aydinlatma = [
    {
      title: "first",
      description: "firstDescription",
      imgUrl: aydinlatma1,
    },
    {
      title: "second",
      description: "secondDescription",
      imgUrl: aydinlatma2,
    },
    {
      title: "third",
      description: "thirdDescription",
      imgUrl: aydinlatma3,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">İç Mimarlık</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Dış Mimarlık</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Aydınlatma Sistemi</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          ic.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          dis.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          aydinlatma.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
